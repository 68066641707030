import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import {
  getBrowserLang,
  Translation,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TranslocoService,
  TRANSLOCO_CONFIG,
} from '@ngneat/transloco';
import { TranslocoLocaleModule, TranslocoLocaleService } from '@ngneat/transloco-locale';
import { TranslocoPersistLangModule, TRANSLOCO_PERSIST_LANG_STORAGE } from '@ngneat/transloco-persist-lang';
import { PERSIST_TRANSLATIONS_STORAGE, TranslocoPersistTranslationsModule } from '@ngneat/transloco-persist-translations';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  #http = new HttpClient(
    new HttpXhrBackend({
      build: () => new XMLHttpRequest(),
    })
  );

  getTranslation(lang: string) {
    return this.#http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

const langToLocaleMapping = {
  en: 'en-US',
  de: 'de-DE',
};

@NgModule({
  imports: [
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: langToLocaleMapping,
    }),
    TranslocoPersistLangModule.forRoot({
      getLangFn(opt) {
        return opt.cachedLang || opt.browserLang || opt.cultureLang || opt.defaultLang;
      },
      storageKey: '@transloco/language',
      storage: [
        {
          provide: TRANSLOCO_PERSIST_LANG_STORAGE,
          useExisting: LOCAL_STORAGE,
        },
        {
          provide: LOCALE_ID,
          deps: [TranslocoService],
          useFactory: (translocoService: TranslocoService) =>
            // FIXME: should optain the language from getLangFn
            // Currently, this provided is being used before getLangFn got called
            // console.log((langToLocaleMapping as any)[translocoService.getActiveLang()]);
            (langToLocaleMapping as any)[translocoService.getActiveLang()]
          ,
        },
      ],
    }),
    TranslocoPersistTranslationsModule.forRoot({
      loader: TranslocoHttpLoader,
      storage: {
        provide: PERSIST_TRANSLATIONS_STORAGE,
        useExisting: LOCAL_STORAGE,
      },
    }),
  ],
  exports: [TranslocoModule, TranslocoLocaleModule, TranslocoPersistTranslationsModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          {
            id: 'en',
            label: 'English',
          },
          {
            id: 'de',
            label: 'Germany',
          },
        ],
        defaultLang: getBrowserLang() || 'de',
        fallbackLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    // {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader},
    {
      // Preload the default language before the app starts to prevent empty/jumping content
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LOCALE_ID, TranslocoLocaleService, TranslocoService],
      useFactory:
        (localeId: string, translocoLocaleService: TranslocoLocaleService, translocoService: TranslocoService): any =>
        () => {
          translocoLocaleService.setLocale(localeId);
          const activeLang = translocoService.getActiveLang();
          translocoService.setActiveLang(activeLang || environment.lang);
          translocoService.load(activeLang || environment.lang);
        },
    },
  ],
})
export class TranslocoRootModule {}
