import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AppGuard, RoutesConstants} from '@tante-tobi-web/infrastructure';
import {PageNotFoundComponent, PageNotFoundComponentModule} from '@tante-tobi-web/partials';
import {ContainerComponent} from './container/container.compnent';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RoutesConstants.CALENDER.withSlash,
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: '',
    canActivate: [AppGuard],
    canActivateChild: [AppGuard],
    component: ContainerComponent,
    children: [
      {
        path: 'applications',
        loadChildren: () =>
          import('./applications/applications.module').then(
            (m) => m.ApplicationsModule
          ),
      },
      {
        path: RoutesConstants.WORKSHOPS.withoutSlash,
        loadChildren: () =>
          import('./workshops/workshops.module').then(
            (module) => module.WorkshopsModule
          ),
        data: { header: 'HEADER.WORKSHOP' },
      },
      {
        path: RoutesConstants.PROFILE.withoutSlash,
        loadChildren: () =>
          import('./profile/profile.module').then(
            (module) => module.ProfileModule
          ),
        data: { header: 'HEADER.PROFILE' },
      },
      {
        path: RoutesConstants.CALENDER.withoutSlash,
        loadChildren: () =>
          import('./calendar/calendar.module').then(
            (module) => module.CalendarModule
          ),
        data: { header: 'HEADER.CALENDER' },
      },
      {
        path: RoutesConstants.BOOKINGS.withoutSlash,
        loadChildren: () =>
          import('./bookings/bookings.module').then(
            (module) => module.BookingsModule
          ),
        data: { header: 'HEADER.BOOKING' },
      },
      {
        path: RoutesConstants.STATEMENTS_LIST.withoutSlash,
        loadChildren: () =>
          import('./statements/statements/statements.module').then(
            (module) => module.StatementsModule
          ),
        data: { header: 'HEADER.STATEMENTS' },
      },
    ],
  },
  {
    path: RoutesConstants.NOT_FOUND.withoutSlash,
    component: PageNotFoundComponent,
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    PageNotFoundComponentModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
