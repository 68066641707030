import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { FactoryModule, FormFactoryModule } from '@ezzabuzaid/ngx-form-factory';
import { TranslocoModule } from '@ngneat/transloco';
import { TableModule } from '@tante-tobi-web/partials';
import { ButtonModule } from '@tante-tobi-web/widgets';
import { WorkshopsService } from '../../services/workshops';
import { StatementsFilterComponent } from './statements-filter/statements-filter.component';
import { StatementsListComponent } from './statements-list/statements-list.component';
import { StatementsRoutingModule } from './statements-routing.module';
import { DownloadInvoicesComponent } from './statements-list/components/download-invoices/download-invoices.component';
import { DirectivesModule } from '@tante-tobi-web/shared';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [StatementsListComponent, StatementsFilterComponent, DownloadInvoicesComponent],
  imports: [
    CommonModule,
    StatementsRoutingModule,
    HttpClientModule,
    TableModule,
    ReactiveFormsModule,
    FormFactoryModule,
    MatIconModule,
    FactoryModule,
    ButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatButtonModule,
    TranslocoModule,
    DirectivesModule,
    MatTooltipModule,
  ],
  exports: [StatementsListComponent, StatementsFilterComponent],
  providers: [WorkshopsService],
})
export class StatementsModule {}
