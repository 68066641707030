import {Observable} from 'rxjs';
import {AuthModel, AbstractAuthService, ApiConstants, TokenManager} from '@tante-tobi-web/infrastructure';
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class AuthService extends AbstractAuthService {
  constructor(protected httpClient: HttpClient,
              protected tokenManager: TokenManager,
              protected router: Router) {
    super(httpClient, tokenManager, router);
  }

  override userRoles : AuthModel.UserRoles[] = ['INSTRUCTOR']

  override changePassword(data: AuthModel.ChangePasswordRequest): Observable<any> {
    return this._httpClient.configure({
      snackbar: {successMessage: 'SUCCESS'}
    }).patch(ApiConstants.PROFILE.changePassword, data);
  }

}
