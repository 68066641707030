import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { PaginationQuery, StatementsModel, StatementsService } from '@tante-tobi-web/infrastructure';
import { Column, FetchProvider } from '@tante-tobi-web/partials';
import { StatementsFilterComponent } from '../statements-filter/statements-filter.component';
import { DownloadInvoicesComponent } from './components/download-invoices/download-invoices.component';

@Component({
  selector: 'instructor-statements-list',
  templateUrl: './statements-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementsListComponent implements OnInit {
  @ViewChild(StatementsFilterComponent, { static: true })
  private _statementsFilterComponent?: StatementsFilterComponent;
  fetchProvider: FetchProvider<StatementsModel.Statements[]> = (query: PaginationQuery) => {
    const filters = this._statementsFilterComponent!.getFilterQuery();
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== '') {
        query.addParams(key, value);
      }
    });
    return this.statementsService.getStatementsList(query);
  };

  displayColumns = [
    new Column({
      key: 'id',
      title: 'Statment ID',
      minWidth: '90px',
      maxWidth: '90px',
    }),
    new Column({
      key: 'workshopName',
      title: 'Workshop Name',
      class: ['truncate', 'pr-4'],
      width: '196px',
      maxWidth: '196px',
      minWidth: '196px',
      valueFormatter: (row, cell) => row['bookingDetails']['workshopName'],
    }),
    new Column({
      key: 'startingDate',
      title: 'Workshop Start Date',
      minWidth: '120px',
      maxWidth: '120px',
      valueFormatter: (row, cell) => row['bookingDetails']['startingDate'],
    }),
    new Column({
      key: 'endingDate',
      title: 'Workshop End Date',
      minWidth: '120px',
      maxWidth: '120px',
      valueFormatter: (row, cell) => row['bookingDetails']['endingDate'],
    }),
    new Column({
      key: 'categoryName',
      title: 'Category',
      minWidth: '110px',
      maxWidth: '110px',
      valueFormatter: (row, cell) => row['bookingDetails']['categoryName'],
    }),
    new Column({
      key: 'totalAmount',
      title: 'Total Amount',
      minWidth: '97px',
      maxWidth: '97px',
      valueFormatter: (row, cell) => row['paymentDetails']['totalAmount'],
    }),
    new Column({
      key: 'tantetobiReceivables',
      title: 'Receivables to TanteTobi',
      minWidth: '94px',
      class: ['px-2'],
      maxWidth: '94px',
      valueFormatter: (row, cell) => row['paymentDetails']['tantetobiReceivables'],
    }),
    new Column({
      key: 'tantetobiPayables',
      title: 'Payables by TanteTobi',
      class: ['px-2'],
      minWidth: '95px',
      maxWidth: '95px',
      valueFormatter: (row, cell) => row['paymentDetails']['tantetobiPayables'],
    }),
    new Column({
      key: 'duration',
      title: 'BOOKING.PREVIEW_INVOICE',
      class: 'px-5',
      translate: true,
      minWidth: '150px',
      maxWidth: '150px',
      component: DownloadInvoicesComponent,
    }),
  ];

  constructor(private statementsService: StatementsService, private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.displayColumns.map((item) => {
      switch (item.title) {
        case 'Statment ID':
          this.translocoService.selectTranslate('FINANCE.STATEMENT_ID').subscribe((value) => {
            item.title = value;
          });
          break;
        case 'Total Amount':
          this.translocoService.selectTranslate('FINANCE.TOTAL_AMOUNT').subscribe((value) => {
            item.title = value;
          });
          break;
        case 'Workshop Name':
          this.translocoService.selectTranslate('FINANCE.WORKSHOP_NAME').subscribe((value) => {
            item.title = value;
          });
          break;
        case 'Category':
          this.translocoService.selectTranslate('FINANCE.CATEGORY').subscribe((value) => {
            item.title = value;
          });
          break;
        case 'Workshop Start Date':
          this.translocoService.selectTranslate('FINANCE.WORKSHOP_START_DATE').subscribe((value) => {
            item.title = value;
          });
          break;
        case 'Workshop End Date':
          this.translocoService.selectTranslate('FINANCE.WORKSHOP_END_DATE').subscribe((value) => {
            item.title = value;
          });
          break;
        case 'Receivables to TanteTobi':
          this.translocoService.selectTranslate('FINANCE.RECEIVABLE_TO_TANTETOBI').subscribe((value) => {
            item.title = value;
          });
          break;
        case 'Payables by TanteTobi':
          this.translocoService.selectTranslate('FINANCE.PAYABLES_BY_TANTETOBI').subscribe((value) => {
            item.title = value;
          });
          break;
        default:
          break;
      }
    });
  }
}
