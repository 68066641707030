export const environment = {
  production: false,
  endpointUrl: 'https://api-dev.tantetobi.de/tantetobi/api/v1/',
  serverOrigin: 'https://api-dev.tantetobi.de/tantetobi/',
  lang: 'en',
  appVersion: require('../../../../package.json').version + '-dev',
  firebase: {
    // NOTE: update firebase-messaging-sw.prod whenever you change these configs
    apiKey: 'AIzaSyAPubUmZABy72R-c7rI7tI1hAcwh9fiq_M',
    authDomain: 'prod-tantetobi.firebaseapp.com',
    projectId: 'prod-tantetobi',
    storageBucket: 'prod-tantetobi.appspot.com',
    messagingSenderId: '607900690360',
    appId: '1:607900690360:web:6888f8ac4ac0485df3514d',
    measurementId: 'G-7F45PXZ7QK',
  },
};
